.search-bar-wrapper {
  width: 100%;

  .input-field {
    font-size: 14px;
    line-height: 16px;
    height: 36px;
    border-radius: 0;
  }
}
