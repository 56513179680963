.layout-wrapper {
  display: flex;

  .side-panel {
    width: "392px";
    padding: "24px";
  }

  .content {
    flex-grow: 100;
    padding: "24px";
  }
}
