@import "../../styles/palette.scss";

.dropdown-wrapper {
  .dropdown-label {
    font-size: 14px;
    line-height: 16px;
    color: $font-light-secondary;
    margin-bottom: 4px;
  }

  .label-container {
    display: flex;
    justify-content: space-between;
  }

  .dropdown-button {
    width: 100%;
    justify-content: space-between;
    color: $font-light-primary;
    background-color: $white-primary;
    border: 1px solid $grey-30;
    border-radius: 0;
    text-transform: unset;
  }

  .dropdown-info {
    margin: 0 6px;
  }
}

/* Material-UI overwrites */
.dropdown-list {
  & > div {
    border-radius: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  & ul {
    border-top: 0;
    border-bottom: 0;

    .Mui-selected,
    .Mui-selected:hover {
      color: $white-primary;
      background-color: $blue-60;
    }
  }
}
