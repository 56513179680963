.panel-section-wrapper {
  margin: 32px 0;

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section-title {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
  }

  .section-arrow {
    margin: 0 6px;
    cursor: pointer;
  }
}
