$blue-60: #3366ff;

$font-light-primary: #1f1f1f;
$font-light-secondary: #696969;

$font-on-color-primary: #ffffff;

$grey-10: #ebebeb;
$grey-30: #bababa;

$main-bg-color: blue;

$white-primary: #ffffff;
